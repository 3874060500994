import { Replica } from "./replica";

export class Sample {
  replicas: Replica[] = [];

  isSingle(): boolean {
    return this.replicas.length === 1;
  }

  getReplicaNames(): string {
    let replicaNames: string = "";

    for (const replica of this.replicas) {
      if (replicaNames.length > 0) {
        replicaNames += ", ";
      }

      replicaNames += replica.name;
    }

    return replicaNames;
  }
}
