import { Locus } from "../models/locus";

export class LociResult {
  name: string = "";
  prevalentAllele1: string = "";
  prevalentAllele2: string = "";

  amountOfAllelesForErrorCalculation: number = 0;
  totalAmountOfAlleles: number = 0;
  amountOfErroneousAlleles: number = 0;
  amountOfAlleleDropOuts: number = 0;
  amountOfErroneousLoci: number = 0;
  amountOfHomozygotes: number = 0;
  amountOfHeterozygotes: number = 0;
  amountOfLoci: number = 0;
  amountOfLociForErrorCalculation: number = 0;
  ambiguous: boolean = false;

  constructor(name: string, loci: Locus[]) {
    this.name = name;

    let filledAlleles: number = 0;

    for (const locus of loci) {
      if (!locus.isEmpty()) {
        filledAlleles++;
        this.totalAmountOfAlleles += 2;
      }

      if (locus.isHomozygot()) {
        this.amountOfHomozygotes++;
      } else if (locus.isHeterozygot()) {
        this.amountOfHeterozygotes++;
      }
    }

    if (filledAlleles < 2) {
      return;
    }

    this.amountOfAlleleDropOuts = getAlleleDropOutAmount(loci);
    const [allele1PrevalentCandidates, allele2PrevalentCandidates] =
      getPrevalentAlleleCandidates(loci);
    this.amountOfAllelesForErrorCalculation =
      getAmountOfAllelesForErrorCalculation(loci);
    this.amountOfLoci = loci.length;
    this.amountOfLociForErrorCalculation =
      getAmountOfLociForErrorCalculation(loci);

    if (loci.length < 2) {
      return;
    }

    let [
      prevalentAllele1,
      prevalentAllele1Ambiguous,
      prevalentAllele1MaxCount,
    ] = getPrevalentAlleleResults(allele1PrevalentCandidates);
    let [
      prevalentAllele2,
      prevalentAllele2Ambiguous,
      prevalentAllele2MaxCount,
    ] = getPrevalentAlleleResults(allele2PrevalentCandidates);

    //Solve ambiguous prevalent alleles using other prevalent allele if found
    if (prevalentAllele1Ambiguous && !prevalentAllele2Ambiguous) {
      prevalentAllele1 = solvePrevalentAlleleUsingOtherPrevalentAllele(
        allele1PrevalentCandidates,
        prevalentAllele1MaxCount,
        prevalentAllele2
      );

      this.ambiguous = true;
    } else if (!prevalentAllele1Ambiguous && prevalentAllele2Ambiguous) {
      prevalentAllele2 = solvePrevalentAlleleUsingOtherPrevalentAllele(
        allele2PrevalentCandidates,
        prevalentAllele2MaxCount,
        prevalentAllele1
      );

      this.ambiguous = true;
    } else if (prevalentAllele1Ambiguous && prevalentAllele2Ambiguous) {
      this.ambiguous = true;
    }

    //Count errors
    const [amountOfErroneousAlleles, amountOfErroneousLoci] =
      calculateAlleleAndLocusErrorAmountFromLoci(
        loci,
        prevalentAllele1,
        prevalentAllele2
      );

    this.amountOfErroneousAlleles = amountOfErroneousAlleles;
    this.amountOfErroneousLoci = amountOfErroneousLoci;

    this.prevalentAllele1 = prevalentAllele1;
    this.prevalentAllele2 = prevalentAllele2;
  }
}

function getAmountOfAllelesForErrorCalculation(loci: Locus[]): number {
  let count: number = 0;

  for (const locus of loci) {
    if (locus.allele1.length > 0) {
      count++;
    }

    if (locus.allele2.length > 0) {
      count++;
    }
  }

  return count;
}

function getAmountOfLociForErrorCalculation(loci: Locus[]): number {
  let count: number = 0;

  for (const locus of loci) {
    if (
      locus.allele1.length > 0 &&
      locus.allele2.length > 0 &&
      locus.allele1 !== "?" &&
      locus.allele2 !== "?"
    ) {
      count++;
    }
  }

  return count;
}

function getPrevalentAlleleCandidates(
  loci: Locus[]
): [{ [k: string]: number }, { [k: string]: number }] {
  const allele1Candidates: { [k: string]: number } = {};
  const allele2Candidates: { [k: string]: number } = {};

  for (const locus of loci) {
    if (locus.allele1.length > 0) {
      if (locus.allele1 !== "?") {
        if (allele1Candidates[locus.allele1]) {
          allele1Candidates[locus.allele1]++;
        } else {
          allele1Candidates[locus.allele1] = 1;
        }
      }
    }

    if (locus.allele2.length > 0) {
      if (locus.allele2 !== "?") {
        if (allele2Candidates[locus.allele2]) {
          allele2Candidates[locus.allele2]++;
        } else {
          allele2Candidates[locus.allele2] = 1;
        }
      }
    }
  }

  return [allele1Candidates, allele2Candidates];
}

function solvePrevalentAlleleUsingOtherPrevalentAllele(
  alleleCandidates: { [k: string]: number },
  prevalentAlleleMaxCount: number,
  otherSolvedPrevalentAllele: string
): string {
  let prevalentAllele: string = "";

  for (const key in alleleCandidates) {
    if (
      alleleCandidates[key] === prevalentAlleleMaxCount &&
      key !== otherSolvedPrevalentAllele
    ) {
      prevalentAllele = key;
    }
  }

  return prevalentAllele;
}

function calculateAlleleAndLocusErrorAmountFromLoci(
  loci: Locus[],
  prevalentAllele1: string,
  prevalentAllele2: string
): [number, number] {
  let alleleErrorCount: number = 0;
  let locusErrorCount: number = 0;

  for (const locus of loci) {
    let locusErrorFound: boolean = false;

    if (
      locus.allele1.length > 0 &&
      locus.allele1 !== "?" &&
      locus.allele1 !== prevalentAllele1
    ) {
      alleleErrorCount++;

      locusErrorCount++;
      locusErrorFound = true;
    }

    if (
      locus.allele2.length > 0 &&
      locus.allele2 !== "?" &&
      locus.allele2 !== prevalentAllele2
    ) {
      alleleErrorCount++;

      if (!locusErrorFound) {
        locusErrorCount++;
      }
    }

    // Loci error is not calculated from single samples
    if (loci.length <= 1) {
      locusErrorCount = 0;
    }
  }

  return [alleleErrorCount, locusErrorCount];
}

function getPrevalentAlleleResults(allelePrevalentCandidates: {
  [k: string]: number;
}): [string, boolean, number] {
  let prevalentAllele: string = "";
  let prevalentAlleleAmbiguous: boolean = false;
  let prevalentAlleleMaxCount: number = 0;

  for (const key in allelePrevalentCandidates) {
    if (allelePrevalentCandidates[key] > prevalentAlleleMaxCount) {
      prevalentAllele = key;
      prevalentAlleleMaxCount = allelePrevalentCandidates[key];
      prevalentAlleleAmbiguous = false;
    } else if (allelePrevalentCandidates[key] === prevalentAlleleMaxCount) {
      prevalentAlleleAmbiguous = true;
    }
  }

  return [prevalentAllele, prevalentAlleleAmbiguous, prevalentAlleleMaxCount];
}

function getAlleleDropOutAmount(loci: Locus[]): number {
  let amountOfHeterozygotes: number = 0;
  let amountOfHomozygotes: number = 0;

  for (const locus of loci) {
    if (locus.isHomozygot()) {
      amountOfHomozygotes++;
    } else if (locus.isHeterozygot()) {
      amountOfHeterozygotes++;
    }
  }

  if (
    amountOfHomozygotes > 0 &&
    amountOfHeterozygotes > 0 &&
    amountOfHomozygotes <= amountOfHeterozygotes
  ) {
    return amountOfHomozygotes;
  }

  return 0;
}
