import { LociResult } from "../analyzer/results/lociResult";
import { Result } from "../analyzer/results/result";

export function writeResultsToCsvFile(result: Result): string {
  var rows: string[] = [
    "Replicate amount;" + result.replicateAmount,
    "Sample amount;" + result.sampleAmount,
    "Single sample amount;" + result.singleSampleAmount,
    "",
    "Total amount of alleles;" + result.amountOfAlleles,
    "Amount of alleles for error calculation;" +
      result.amountOfAllelesForErrorCalculation,
    "Erroneous alleles;" + result.amountOfErroneousAlleles,
    "Error rate;" + result.errorRate.toPrecision(7).replace(".", ","), //strings.Replace(strconv.FormatFloat(result.ErrorRate, 'f', 6, 64), ".", ",", -1),
    "",
    "Amount of heterozygotes;" + result.amountOfHeterozygotes,
    "Amount of homozygotes;" + result.amountOfHomozygotes,
    "Allele dropout rate per heterozygous loci;" +
      result.alleleDropoutRatePerHeterozygousLoci
        .toPrecision(7)
        .replace(".", ","), //strings.Replace(strconv.FormatFloat(result.AlleleDropoutRatePerHeterozygousLoci, 'f', 6, 64), ".", ",", -1),
    "Allele dropout rate per all loci;" +
      result.alleleDropoutRatePerAllLoci.toPrecision(7).replace(".", ","), // strings.Replace(strconv.FormatFloat(result.AlleleDropoutRatePerAllLoci, 'f', 6, 64), ".", ",", -1),
    "Allele dropout rate per all alleles;" +
      result.alleleDropoutRatePerAllAlleles.toPrecision(7).replace(".", ","), //strings.Replace(strconv.FormatFloat(result.AlleleDropoutRatePerAllAlleles, 'f', 6, 64), ".", ",", -1),
    "Other error rate per all loci;" +
      result.otherErrorRatePerAllLoci.toPrecision(7).replace(".", ","), //strings.Replace(strconv.FormatFloat(result.OtherErrorRatePerAllLoci, 'f', 6, 64), ".", ",", -1),
    "Other error rate per all alleles;" +
      result.otherErrorRatePerAllAlleles.toPrecision(7).replace(".", ","), //strings.Replace(strconv.FormatFloat(result.OtherErrorRatePerAllAlleles, 'f', 6, 64), ".", ",", -1),
    "",
    "Loci total count;" + result.amountOfLoci,
    "Loci total count for error calculation;" +
      result.amountOfLociForErrorCalculation,
    "Loci error count;" + result.amountOfErroneousLoci,
    "Loci error;" + result.lociErrorRate.toPrecision(7).replace(".", ","), //strings.Replace(strconv.FormatFloat(result.LociErrorRate, 'f', 6, 64), ".", ",", -1),
    "",
    "SAMPLES;Allele drop outs;Allele drop outs rate;Other errors;Other errors rate;Total error count;Total error rate;Total alleles;Heterozygotes;Homozygotes;Allele dropout rate per heterozygous loci;Allele dropout rate per all loci;Other error rate per all loci;Amount of loci for error calculation;Amount of erroneous loci;",
  ];

  for (const sampleResult of result.sampleResults) {
    if (sampleResult.single) {
      rows.push("Sample " + sampleResult.name + " (Single)");
    } else {
      let sampleErrors = 0;
      let sampleAlleles = 0;
      let sampleAlleleDropOuts = 0;
      let sampleHeterozygotes = 0;
      let sampleHomozygotes = 0;
      let sampleAmountOfLociForErrorCalculation = 0;
      let sampleAmountOfErroneousLoci = 0;

      const ambiguousLociResults: LociResult[] = [];

      for (const lociResult of sampleResult.lociResults) {
        sampleErrors += lociResult.amountOfErroneousAlleles;
        sampleAlleles += lociResult.totalAmountOfAlleles;
        sampleAlleleDropOuts += lociResult.amountOfAlleleDropOuts;
        sampleHeterozygotes += lociResult.amountOfHeterozygotes;
        sampleHomozygotes += lociResult.amountOfHomozygotes;
        sampleAmountOfLociForErrorCalculation +=
          lociResult.amountOfLociForErrorCalculation;
        sampleAmountOfErroneousLoci += lociResult.amountOfErroneousLoci;

        if (lociResult.ambiguous) {
          ambiguousLociResults.push(lociResult);
        }
      }

      rows.push(
        "Sample " +
          sampleResult.name +
          ";" +
          sampleAlleleDropOuts.toString() +
          ";" +
          (sampleAlleleDropOuts / sampleAlleles)
            .toPrecision(7)
            .replace(".", ",") +
          ";" + //strings.Replace(strconv.FormatFloat(float64(sampleAlleleDropOuts)/float64(sampleAlleles), 'f', 6, 64), ".", ",", -1)+";"+
          (sampleErrors - sampleAlleleDropOuts) +
          ";" +
          ((sampleErrors - sampleAlleleDropOuts) / sampleAlleles)
            .toPrecision(7)
            .replace(".", ",") +
          ";" + //strings.Replace(strconv.FormatFloat(float64(sampleErrors-sampleAlleleDropOuts)/float64(sampleAlleles), 'f', 6, 64), ".", ",", -1)+";"+
          sampleErrors +
          ";" +
          (sampleErrors / sampleAlleles).toPrecision(7).replace(".", ",") +
          ";" + //strings.Replace(strconv.FormatFloat(float64(sampleErrors)/float64(sampleAlleles), 'f', 6, 64), ".", ",", -1)+";"+
          sampleAlleles +
          ";" +
          sampleHeterozygotes +
          ";" +
          sampleHomozygotes +
          ";" +
          (sampleAlleleDropOuts / sampleHeterozygotes)
            .toPrecision(7)
            .replace(".", ",") +
          ";" + //strings.Replace(strconv.FormatFloat(float64(sampleAlleleDropOuts)/float64(sampleHeterozygotes), 'f', 6, 64), ".", ",", -1)+";"+
          (sampleAlleleDropOuts / sampleAmountOfLociForErrorCalculation)
            .toPrecision(7)
            .replace(".", ",") +
          ";" + //strings.Replace(strconv.FormatFloat(float64(sampleAlleleDropOuts)/float64(sampleAmountOfLociForErrorCalculation), 'f', 6, 64), ".", ",", -1)+";"+
          (
            (sampleAmountOfErroneousLoci - sampleAlleleDropOuts) /
            sampleAmountOfLociForErrorCalculation
          )
            .toPrecision(7)
            .replace(".", ",") +
          ";" + //strings.Replace(strconv.FormatFloat(float64(sampleAmountOfErroneousLoci-sampleAlleleDropOuts)/float64(sampleAmountOfLociForErrorCalculation), 'f', 6, 64), ".", ",", -1)+";"+
          sampleAmountOfLociForErrorCalculation +
          ";" +
          sampleAmountOfErroneousLoci
      );

      for (const ambiguousLociResult of ambiguousLociResults) {
        rows.push("AMBIGUOUS LOCI RESULT (" + ambiguousLociResult.name + ")");
      }
    }
  }

  rows.push("");
  rows.push("LOCI");

  for (const lociName of result.lociNamesInOrder) {
    const lociResultGroup = result.lociResults[lociName];
    let lociErrors = 0;
    let lociAlleleDropOuts = 0;
    let lociAlleles = 0;
    let lociHeterozygotes = 0;
    let lociHomozygotes = 0;
    let lociAmountOfLociForErrorCalculation = 0;
    let lociAmountOfErroneousLoci = 0;

    for (const lociResult of lociResultGroup) {
      lociErrors += lociResult.amountOfErroneousAlleles;
      lociAlleles += lociResult.amountOfAllelesForErrorCalculation;
      lociAlleleDropOuts += lociResult.amountOfAlleleDropOuts;
      lociHeterozygotes += lociResult.amountOfHeterozygotes;
      lociHomozygotes += lociResult.amountOfHomozygotes;
      lociAmountOfLociForErrorCalculation +=
        lociResult.amountOfLociForErrorCalculation;
      lociAmountOfErroneousLoci += lociResult.amountOfErroneousLoci;
    }

    rows.push(
      "Loci " +
        lociName +
        ";" +
        lociAlleleDropOuts +
        ";" +
        (lociAlleleDropOuts / lociAlleles).toPrecision(7).replace(".", ",") +
        ";" + //strings.Replace(strconv.FormatFloat(float64(lociAlleleDropOuts)/float64(lociAlleles), 'f', 6, 64), ".", ",", -1)+";"+
        (lociErrors - lociAlleleDropOuts) +
        ";" +
        ((lociErrors - lociAlleleDropOuts) / lociAlleles)
          .toPrecision(7)
          .replace(".", ",") +
        ";" + //strings.Replace(strconv.FormatFloat(float64(lociErrors-lociAlleleDropOuts)/float64(lociAlleles), 'f', 6, 64), ".", ",", -1)+";"+
        lociErrors +
        ";" +
        (lociErrors / lociAlleles).toPrecision(7).replace(".", ",") +
        ";" + //strings.Replace(strconv.FormatFloat(float64(lociErrors)/float64(lociAlleles), 'f', 6, 64), ".", ",", -1)+";"+
        lociAlleles +
        ";" +
        lociHeterozygotes +
        ";" +
        lociHomozygotes +
        ";" +
        (lociAlleleDropOuts / lociHeterozygotes)
          .toPrecision(7)
          .replace(".", ",") +
        ";" + //strings.Replace(strconv.FormatFloat(float64(lociAlleleDropOuts)/float64(lociHeterozygotes), 'f', 6, 64), ".", ",", -1)+";"+
        (lociAlleleDropOuts / lociAmountOfLociForErrorCalculation)
          .toPrecision(7)
          .replace(".", ",") +
        ";" + //strings.Replace(strconv.FormatFloat(float64(lociAlleleDropOuts)/float64(lociAmountOfLociForErrorCalculation), 'f', 6, 64), ".", ",", -1)+";"+
        (
          (lociAmountOfErroneousLoci - lociAlleleDropOuts) /
          lociAmountOfLociForErrorCalculation
        )
          .toPrecision(7)
          .replace(".", ",") +
        ";" + //strings.Replace(strconv.FormatFloat(float64(lociAmountOfErroneousLoci-lociAlleleDropOuts)/float64(lociAmountOfLociForErrorCalculation), 'f', 6, 64), ".", ",", -1)+";"+
        lociAmountOfLociForErrorCalculation +
        ";" +
        lociAmountOfErroneousLoci
    );
  }

  let fileContents: string = "";
  for (const row of rows) {
    fileContents += row + "\n";
  }

  return fileContents;
}
