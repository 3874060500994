import { LociResult } from "./lociResult";

export class SampleResult {
  name: string = "";
  single: boolean = false;
  lociResults: LociResult[] = [];

  getErrorRate(): number {
    let errors: number = 0;
    let alleles: number = 0;

    for (const lociResult of this.lociResults) {
      errors += lociResult.amountOfErroneousAlleles;
      alleles += lociResult.amountOfAllelesForErrorCalculation;
    }

    return errors / alleles;
  }
}
