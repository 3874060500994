import React, { useState } from "react";
import { getResultFromImportData } from "./analyzer/analyzer";
import {
  readCsvDataMatrixToImportData,
  readCsvToDataLines,
} from "./reader/csvReader";
import { writeResultsToCsvFile } from "./writer/csvWriter";

const App = () => {
  const [name, setName] = useState("");
  const [selectedFile, setSelectedFile] = useState("");

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (!e.target.files) {
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      if (!e.target) {
        return;
      }

      const data = e.target.result?.toString();

      if (!data) {
        return;
      }

      const csvDataLines = readCsvToDataLines(data);
      console.log(csvDataLines);
      const [importData, importErrorMessage] =
        readCsvDataMatrixToImportData(csvDataLines);

      if (importErrorMessage.length > 0) {
        alert(importErrorMessage);
        return;
      }
      console.log(importData);

      const [result, resultErrorMessage] = getResultFromImportData(importData);

      if (resultErrorMessage.length > 0) {
        alert(resultErrorMessage);
        return;
      }

      const csvFileContents = writeResultsToCsvFile(result);
      downloadCSVFile(csvFileContents, "microsaterrcalc_output.csv");
    };

    reader.readAsText(e.target.files[0]);
  };

  return (
    <div className="App">
      <p>
        Choose the input file and you will be prompted to download the output
        file.
      </p>
      <p>
        Based on:{" "}
        <a
          href="https://github.com/karimerikanto/microsat_errcalc"
          target="_blank"
          rel="noreferrer"
        >
          https://github.com/karimerikanto/microsat_errcalc
        </a>
      </p>
      <form>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <input
          type="file"
          value={selectedFile}
          onChange={handleFileChange}
          accept=".csv"
        />
      </form>
    </div>
  );
};

export default App;

function downloadCSVFile(csvData: string, filename: string) {
  var csv_file, download_link;

  csv_file = new Blob([csvData], { type: "text/csv" });
  download_link = document.createElement("a");
  download_link.download = filename;
  download_link.href = window.URL.createObjectURL(csv_file);
  download_link.style.display = "none";
  document.body.appendChild(download_link);
  download_link.click();
  download_link.remove();
}
