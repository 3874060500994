export class Locus {
  name: string = "";
  allele1: string = "";
  allele2: string = "";

  isEmpty(): boolean {
    return this.allele1.length === 0 && this.allele2.length === 0;
  }

  isHomozygot(): boolean {
    return (
      !this.isEmpty() &&
      this.allele1 !== "?" &&
      this.allele2 !== "?" &&
      this.allele1 === this.allele2
    );
  }

  isHeterozygot(): boolean {
    return (
      !this.isEmpty() &&
      this.allele1 !== "?" &&
      this.allele2 !== "?" &&
      this.allele1 !== this.allele2
    );
  }
}
