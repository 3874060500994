import { ImportData } from "./models/importData";
import { Locus } from "./models/locus";
import { Replica } from "./models/replica";
import { LociResult } from "./results/lociResult";
import { Result } from "./results/result";
import { SampleResult } from "./results/sampleResult";

//GetResultFromImportData analyzes given import data and returns the result object
export function getResultFromImportData(
  importData: ImportData
): [Result, string] {
  const result: Result = new Result();

  if (importData.samples.length === 0) {
    return [result, ""];
  }

  //Validate loci names
  const lociNameCounts: { [k: string]: boolean } = {};
  const firstSample = importData.samples[0];
  const firstSampleFirstReplica = firstSample.replicas[0];

  for (const locus of firstSampleFirstReplica.loci) {
    if (lociNameCounts[locus.name]) {
      return [
        result,
        "Multiple same loci names found. Loci names must be unique",
      ];
    }

    lociNameCounts[locus.name] = true;
  }

  result.sampleAmount = importData.samples.length;
  let lociOrdersFilled = false;

  for (
    let sampleArrayIndex = 0;
    sampleArrayIndex < importData.samples.length;
    sampleArrayIndex++
  ) {
    const sample = importData.samples[sampleArrayIndex];
    const sampleResult: SampleResult = new SampleResult();

    sampleResult.name = "(" + sample.getReplicaNames() + ")";
    result.replicateAmount += sample.replicas.length;
    sampleResult.single = sample.isSingle();

    //Fill loci orders
    if (!lociOrdersFilled) {
      for (const replica of sample.replicas) {
        for (const locus of replica.loci) {
          result.lociNamesInOrder.push(locus.name);
        }
        break;
      }

      lociOrdersFilled = true;
    }

    //Skip single samples
    if (sampleResult.single) {
      result.singleSampleAmount++;

      for (const replica of sample.replicas) {
        for (const locus of replica.loci) {
          if (locus.allele1.length > 0) {
            result.amountOfAlleles++;
          }
          if (locus.allele2.length > 0) {
            result.amountOfAlleles++;
          }

          if (locus.allele1.length > 0 && locus.allele2.length > 0) {
            result.amountOfLoci++;
          }
        }
      }

      result.sampleResults.push(sampleResult);

      continue;
    }

    // Flip locuses and replicas
    const flippedSampleLocusArray = getFlippedLocusArrayUsingReplicaArray(
      sample.replicas
    );

    // Create loci results for samples and for the whole result
    for (const loci of flippedSampleLocusArray) {
      if (loci.length > 0) {
        const locusName = loci[0].name;
        const lociResult = new LociResult(locusName, loci);
        sampleResult.lociResults.push(lociResult);

        result.amountOfAlleles += lociResult.totalAmountOfAlleles;
        result.amountOfAllelesForErrorCalculation +=
          lociResult.amountOfAllelesForErrorCalculation;
        result.amountOfErroneousAlleles += lociResult.amountOfErroneousAlleles;
        result.amountOfLoci += lociResult.amountOfLoci;
        result.amountOfLociForErrorCalculation +=
          lociResult.amountOfLociForErrorCalculation;

        result.amountOfErroneousLoci += lociResult.amountOfErroneousLoci;

        result.amountOfHeterozygotes += lociResult.amountOfHeterozygotes;
        result.amountOfHomozygotes += lociResult.amountOfHomozygotes;
        result.amountOfAlleleDropouts += lociResult.amountOfAlleleDropOuts;
        result.amountOfOtherErrors +=
          lociResult.amountOfErroneousAlleles -
          lociResult.amountOfAlleleDropOuts;

        if (result.lociResults[locusName]) {
          result.lociResults[locusName].push(lociResult);
        } else {
          result.lociResults[locusName] = [lociResult];
        }
      }
    }

    result.sampleResults.push(sampleResult);

    result.errorRate =
      result.amountOfErroneousAlleles /
      result.amountOfAllelesForErrorCalculation;
    result.alleleDropoutRatePerHeterozygousLoci =
      result.amountOfAlleleDropouts / result.amountOfHeterozygotes;
    result.alleleDropoutRatePerAllLoci =
      result.amountOfAlleleDropouts / result.amountOfLociForErrorCalculation;
    result.alleleDropoutRatePerAllAlleles =
      result.amountOfAlleleDropouts / result.amountOfAllelesForErrorCalculation;
    result.otherErrorRatePerAllLoci =
      (result.amountOfErroneousLoci - result.amountOfAlleleDropouts) /
      result.amountOfLociForErrorCalculation;
    result.otherErrorRatePerAllAlleles =
      result.amountOfOtherErrors / result.amountOfAllelesForErrorCalculation;
    result.lociErrorRate =
      result.amountOfErroneousLoci / result.amountOfLociForErrorCalculation;
  }

  return [result, ""];
}

function getFlippedLocusArrayUsingReplicaArray(replicas: Replica[]): Locus[][] {
  const flippedSampleLocusArray: Locus[][] = [[]];

  for (let locusIndex = 0; locusIndex < replicas[0].loci.length; locusIndex++) {
    flippedSampleLocusArray.push([]);
  }

  for (let replicaIndex = 0; replicaIndex < replicas.length; replicaIndex++) {
    const replica = replicas[replicaIndex];

    for (let locusIndex = 0; locusIndex < replica.loci.length; locusIndex++) {
      flippedSampleLocusArray[locusIndex].push(replica.loci[locusIndex]);
    }
  }

  return flippedSampleLocusArray;
}
