import { Locus } from "./locus";
import { Sample } from "./sample";

export class ImportData {
  headers: string[][] = [[]];
  samples: Sample[] = [];
  lociGroups: { [name: string]: Locus[] } = {};

  appendLoci(loci: Locus[]) {
    for (const locus of loci) {
      if (!this.lociGroups[locus.name]) {
        this.lociGroups[locus.name] = [locus];
      } else {
        this.lociGroups[locus.name].push(locus);
      }
    }
  }
}
