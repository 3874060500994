import { LociResult } from "./lociResult";
import { SampleResult } from "./sampleResult";

export class Result {
  sampleResults: SampleResult[] = [];
  lociResults: { [name: string]: LociResult[] } = {};
  lociNamesInOrder: string[] = [];

  replicateAmount: number = 0;
  sampleAmount: number = 0;
  singleSampleAmount: number = 0;

  amountOfLoci: number = 0;
  amountOfLociForErrorCalculation: number = 0;
  amountOfErroneousLoci: number = 0;
  amountOfErroneousAlleles: number = 0;
  amountOfAlleles: number = 0;
  amountOfAllelesForErrorCalculation: number = 0;
  amountOfHeterozygotes: number = 0;
  amountOfHomozygotes: number = 0;
  amountOfAlleleDropouts: number = 0;
  amountOfOtherErrors: number = 0;

  errorRate: number = 0;
  alleleDropoutRatePerHeterozygousLoci: number = 0;
  alleleDropoutRatePerAllLoci: number = 0;
  alleleDropoutRatePerAllAlleles: number = 0;
  otherErrorRatePerAllLoci: number = 0;
  otherErrorRatePerAllAlleles: number = 0;
  lociErrorRate: number = 0;
}
