import { ImportData } from "../analyzer/models/importData";
import { Locus } from "../analyzer/models/locus";
import { Sample } from "../analyzer/models/sample";

enum ReadState {
  Headers = 1,
  LocusNames = 2,
  Samples = 3,
}

export function readCsvToDataLines(data: string): string[][] {
  const dataLines: string[][] = [];
  const lines = data.split("\r\n");

  for (const line of lines) {
    dataLines.push(line.split(";"));
  }

  return dataLines;
}

export function readCsvDataMatrixToImportData(
  dataLines: string[][]
): [ImportData, string] {
  let state: ReadState = ReadState.Headers;
  var samples: Sample[] = [];
  var locusNames: string[] = [];
  var headers: string[][] = [[]];
  var importData = new ImportData();
  let previousSampleRowWasEmpty = true;

  for (
    let dataLineIndex = 0;
    dataLineIndex < dataLines.length;
    dataLineIndex++
  ) {
    const dataLine = dataLines[dataLineIndex];

    switch (state) {
      case ReadState.Headers:
        if (!isHeaderLine(dataLine)) {
          state = ReadState.LocusNames;
          dataLineIndex--;
          break;
        }

        headers.push(dataLine);
        break;
      case ReadState.LocusNames:
        if (!isEmpty(dataLine) && !isLocusRow(dataLine)) {
          state = ReadState.Samples;
          dataLineIndex--;
          break;
        } else if (isLocusRow(dataLine)) {
          for (const value of dataLine) {
            if (value.length > 0) {
              locusNames.push(value);
            }
          }
        }
        break;
      case ReadState.Samples:
        //TODO: Read sample locuses in different matrix dimension for better performance and less memory usage?
        if (isSampleRow(dataLine)) {
          const sampleName = dataLine[0];
          const loci = readLocusArray(dataLine, locusNames);

          importData.appendLoci(loci);

          if (previousSampleRowWasEmpty) {
            samples.push(new Sample());
          }

          previousSampleRowWasEmpty = false;
          const replicas = samples[samples.length - 1].replicas;

          //WORKS??
          replicas.push({
            loci: loci,
            name: sampleName,
          });
        } else if (samples.length > 0 && isEmpty(dataLine)) {
          previousSampleRowWasEmpty = true;
        }
    }
  }

  importData.samples = samples;
  importData.headers = headers;

  return [importData, ""];
}

function isEmpty(lineDatas: string[]): boolean {
  let isEmpty = true;
  for (const value of lineDatas) {
    if (value.length > 0) {
      isEmpty = false;
    }
  }

  return isEmpty;
}

function isHeaderLine(lineDatas: string[]): boolean {
  if (isEmpty(lineDatas) || lineDatas[0].length === 0) {
    return false;
  }

  return true;
}

function isLocusRow(lineDatas: string[]): boolean {
  if (
    isEmpty(lineDatas) ||
    lineDatas.length < 2 ||
    lineDatas[0].length > 0 ||
    lineDatas[1].length === 0
  ) {
    return false;
  }

  return true;
}

function isSampleRow(lineDatas: string[]): boolean {
  if (isEmpty(lineDatas) || lineDatas.length < 2) {
    return false;
  }

  return true;
}

function readLocusArray(lineDatas: string[], locusNames: string[]): Locus[] {
  const locusArray: Locus[] = [];

  for (let i = 0; i < locusNames.length; i++) {
    const locusIndex = i * 2 + 1;
    let locus = new Locus();
    locus.name = locusNames[i];

    if (locusIndex < lineDatas.length) {
      locus.allele1 = lineDatas[locusIndex];
      locus.allele2 = lineDatas[locusIndex + 1];
    }

    locusArray.push(locus);
  }

  return locusArray;
}
